import React, { useState } from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';
import TextField from '@andes/textfield';
import Checkbox from '@andes/checkbox';
import Button from '@andes/button';

import FiltersColors from '../filters-colors/filters-colors.desktop';
import { objValuesPropTypes, valuesPropTypes } from '../filters/propTypes';
import IconNotFound from '../../../../components-v2/icons/sources/not-found';
import VehicleBodyItem from '../../../vehicle-body-selector/vehicle-body-item';
import { FilterLink } from '../../../../components-v2/link';
import useFilterValuesMultiSelect from '../../../../hooks/use-filter-values-multiselect';

const namespace = 'ui-search-search-modal';
const filterNamespace = 'ui-search-filter';

const MODAL_TYPES = {
  WITH_SEARCH: 'WITH_SEARCH',
  DEFAULT: 'DEFAULT',
};

const renderImageFilter = ({ name, url, id, logo_url }) => (
  <FilterLink className={`${namespace}-image-filter`} href={url} key={id} title={name}>
    <div className={`${namespace}-image-filter__container`}>
      <Image alt={name} className={`${namespace}-image-filter__image`} src={logo_url} lowEnd />
    </div>
    <div className={`${namespace}-image-filter__info`}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </div>
  </FilterLink>
);

const renderFilter = (props) => {
  const { name, url, id, logo_url } = props;

  if (logo_url) {
    return renderImageFilter(props);
  }

  return (
    <FilterLink className={`${namespace}-filter`} href={url} key={id} title={name}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </FilterLink>
  );
};

renderFilter.propTypes = {
  ...objValuesPropTypes,
};

const renderFilterGroup = (filter, renderer) =>
  Object.keys(filter).map((key) => (
    <div className={`${namespace}-filter-group`} key={key}>
      <div className={`${namespace}-filters-key`}>{key}</div>
      <div className={`${namespace}-filters`}>{filter[`${key}`].map(renderer)}</div>
    </div>
  ));

renderFilterGroup.propTypes = {
  filter: shape(objValuesPropTypes),
};

const renderColorFilter = ({ id, name, results, url }) => (
  <FilterLink
    className={`${namespace}-color-filter ${filterNamespace}-dd ${filterNamespace}-dd-color `}
    key={id}
    href={url}
    ariaLabel={`${name} ${results}`}
    title={name}
  >
    <FiltersColors name={name} id={id} className={`${namespace}-color-sample`} />
    <div className={`${namespace}-filter-data`}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </div>
  </FilterLink>
);

renderColorFilter.propTypes = {
  ...objValuesPropTypes,
};

const renderSizeFilter = ({ id, name, results, url }, filterName) => (
  <FilterLink
    className={`${namespace}-size-filter`}
    key={id}
    href={url}
    ariaLabel={`${filterName} ${name} ${results}`}
    title={name}
  >
    <div className={`${namespace}-filter-data`}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </div>
  </FilterLink>
);

renderSizeFilter.propTypes = {
  ...objValuesPropTypes,
};

renderSizeFilter.propTypes = {
  ...objValuesPropTypes,
};

const renderVehicleBodyFilter = ({ id, name, url, image }) => (
  <VehicleBodyItem key={id} value={{ id, name, url }} image={image} />
);

const renderMultiSelection = ({ id, name, handleSelectValue, isSelected }) => (
  <div className={`${namespace}-filter ${namespace}-filter--multi-selection`} key={id}>
    <Checkbox
      className={`${namespace}-filter-name`}
      inputProps={{ name, value: name }}
      label={<span>{name}</span>}
      checked={isSelected}
      onChange={(e) => handleSelectValue(id, e)}
    />
  </div>
);

const filterLetter = (values, renderer) => {
  const localValues = JSON.parse(JSON.stringify(values));
  const filter = {};

  let arr = [];

  const orderedValues = localValues.sort((a, b) => {
    const nameA = a.name
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const nameB = b.name
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    if (nameA > nameB) {
      return 1;
    }

    if (nameA < nameB) {
      return -1;
    }

    return 0;
  });

  orderedValues.forEach((value) => {
    if (
      filter[
        value.name[0]
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ]
    ) {
      arr.push(value);
    } else {
      arr = [];
      arr.push(value);
    }

    filter[
      value.name[0]
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    ] = arr;
  });

  return renderFilterGroup(filter, renderer);
};

const renderSearchBar = (modal_place_holder, handleChange) => (
  <div className={`${namespace}__filter-bar`}>
    <TextField data-testid="search_bar" placeholder={modal_place_holder} textbox onChange={handleChange} />
  </div>
);

const getGrouper = (modalType) => {
  switch (modalType) {
    case MODAL_TYPES.WITH_SEARCH:
      return filterLetter;
    case MODAL_TYPES.DEFAULT:
      return null;

    default:
      return null;
  }
};

const getSearchBar = (modalType, modal_place_holder, handleChange) => {
  switch (modalType) {
    case MODAL_TYPES.WITH_SEARCH:
      return renderSearchBar(modal_place_holder, handleChange);
    case MODAL_TYPES.DEFAULT:
      return null;

    default:
      return null;
  }
};

const getGridModifier = (filterId) => {
  switch (filterId) {
    case 'MAIN_COLOR':
    case 'FILTRABLE_COLOR':
    case 'SIZE':
      return 'grid-rows';

    default:
      return 'grid-columns';
  }
};

const getContentModifier = (modalType, filterId) => {
  switch (modalType) {
    case MODAL_TYPES.WITH_SEARCH:
      return 'list';
    case MODAL_TYPES.DEFAULT:
      return getGridModifier(filterId);

    default:
      return null;
  }
};

const ModalContentNotFound = ({ labelNotFound }) => (
  <div className="modal-content-not-found">
    <IconNotFound className={`${namespace}__icon`} />
    <p className="modal-content-not-found__text">{labelNotFound}</p>
  </div>
);

ModalContentNotFound.propTypes = {
  labelNotFound: string,
};

const SearchModal = ({ id, type, values, title, contentRenderer, labels, multi_selection }) => {
  const [filteredValues, setFilteredValues] = useState(values);
  const [scrolled, setScrolled] = useState(false);
  const [canScroll, setCanScroll] = useState(true);
  const { handleSelectValue, isValueSelected, handleSubmit, showSubmit } = useFilterValuesMultiSelect({
    filterId: id,
    multiSelection: multi_selection,
    allValues: filteredValues,
    updateAllValues: setFilteredValues,
  });

  const labelNotFound = labels?.modal_not_found_message;
  const labelPlaceholder = labels?.modal_place_holder;

  const handleChange = (e) => {
    const filter = values.filter(
      (value) =>
        value.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(e.target.value.toLowerCase()) !== -1,
    );

    setFilteredValues(filter);
  };

  const handleScroll = (event) => {
    setScrolled(event.target.scrollTop > 0);
    setCanScroll(event.target.scrollTop + event.target.clientHeight + 10 <= event.target.scrollHeight);
  };

  const renderFilterValue = (filterValue) =>
    contentRenderer(
      {
        ...filterValue,
        handleSelectValue,
        isSelected: isValueSelected(filterValue),
      },
      title,
    );

  const grouper = getGrouper(type);

  return (
    <div
      id="modal"
      className={classnames(namespace, {
        [`${namespace}--scrolled`]: scrolled,
        [`${namespace}__scroll-fade`]: canScroll,
      })}
    >
      <div className={`${namespace}__header`}>{getSearchBar(type, labelPlaceholder, handleChange)}</div>
      {filteredValues.length === 0 ? (
        <ModalContentNotFound labelNotFound={labelNotFound} />
      ) : (
        <div onScroll={handleScroll} className={`${namespace}-${getContentModifier(type, id)}`}>
          {grouper ? grouper(filteredValues, renderFilterValue) : filteredValues.map(renderFilterValue)}
        </div>
      )}
      {showSubmit && (
        <div className={`${namespace}__footer`}>
          <Button hierarchy="loud" size="medium" className={`${namespace}__submit-button`} onClick={handleSubmit}>
            {multi_selection.submit_text}
          </Button>
        </div>
      )}
    </div>
  );
};

SearchModal.propTypes = {
  ...valuesPropTypes,
};

const getContentRenderer = (props) => {
  if (props.multi_selection) {
    return renderMultiSelection;
  }

  switch (props.id) {
    case 'MAIN_COLOR':
    case 'FILTRABLE_COLOR':
      return renderColorFilter;
    case 'SIZE':
    case 'FILTRABLE_SIZE':
      return renderSizeFilter;
    case 'VEHICLE_BODY_TYPE':
      return props.image ? renderVehicleBodyFilter : renderFilter;

    default:
      return renderFilter;
  }
};

// TODO: Mover la lógica que determina el renderer y el grouper a un middleware.
const SearchModalContainer = (props) => <SearchModal {...props} contentRenderer={getContentRenderer(props)} />;

SearchModalContainer.propTypes = {
  ...valuesPropTypes,
};

export default SearchModalContainer;
