import React from 'react';

import { getHeaderComponent } from './utils/constants';
import useHeader from './utils/use-header';
import { useCurrentLayout } from '../../components/context/layout';
import { useStaticProps } from '../../components/context/static-props';

const ChunkHeaderModal = () => {
  const { deviceType } = useStaticProps();
  const currentLayout = useCurrentLayout();
  const headerProps = useHeader() ?? null;

  if (!headerProps) {
    return null;
  }

  const HeaderComponent = getHeaderComponent(headerProps, deviceType);

  if (!HeaderComponent) {
    return null;
  }

  return <HeaderComponent className={`ui-search-header--${currentLayout.id}`} {...headerProps} />;
};

export default ChunkHeaderModal;
