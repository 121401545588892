import React from 'react';

import Label from '../components/label';
import { COMPONENT_TYPE, PACKAGE_NAMESPACE_LABEL } from '../constants';
import { hasOwnProperty } from './utilities';
import SOURCES from './sources';

const { FN_ICON, JSX, SINGLE_TAG } = COMPONENT_TYPE;

const renderStyledLabel = (replacement, index) => (
  <Label
    text={replacement.text}
    styles={replacement.styles}
    className={replacement.className}
    key={`${PACKAGE_NAMESPACE_LABEL}-pb-${index}`}
  />
);

const renderComponentsFromBuilder = ({ result, values, props }) => {
  result.map((item, index) => {
    const replacement = values?.find((replace) => hasOwnProperty(replace, `{${item}}`));

    if (replacement) {
      const { type } = replacement;
      const typeInUppercase = type?.toUpperCase();

      if (!typeInUppercase) {
        return null;
      }

      const { [typeInUppercase]: element } = SOURCES;

      if (element) {
        const { kind, Component } = element;

        if (kind === JSX) {
          result[index] = <Component {...replacement} key={`${PACKAGE_NAMESPACE_LABEL}-pb-`} />;
        } else if (kind === FN_ICON) {
          result[index] = Component({
            ...replacement,
            iconId: replacement?.icon_id,
            className: `${PACKAGE_NAMESPACE_LABEL}-pb-${index}`,
          });
        } else if (kind === SINGLE_TAG) {
          // TODO: fix key
          result[index] = <Component key={`${PACKAGE_NAMESPACE_LABEL}-pb-`} />;
        } else {
          result[index] = renderStyledLabel(replacement, index);
        }
      } else {
        result[index] = renderStyledLabel(replacement, index);
      }
    } else if (item !== '') {
      result[index] = <Label text={props.text} styles={props.styles} className={props.className} />;
    }

    return null;
  });

  return result;
};

export default renderComponentsFromBuilder;
