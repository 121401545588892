import React from 'react';
import { string, shape } from 'prop-types';

import classNames from 'classnames';
import { MoneyAmount } from '@andes/money-amount';

import { getFormattedIntFraction, getFormattedDecimalFraction } from '../../../utils/format-price';
import { PACKAGE_NAMESPACE_LABEL } from '../constants';

const NAMESPACE = `${PACKAGE_NAMESPACE_LABEL}-price__part`;

const Price = ({ className, currencyId, decimal, id, thousandSeparator = '.', styles, text, size = 16 }) => {
  const intFraction = getFormattedIntFraction(text, thousandSeparator);
  const decimalFraction = getFormattedDecimalFraction(decimal);

  return (
    <div className={classNames(NAMESPACE, className, id)} style={styles}>
      <MoneyAmount
        value={{
          decimal_separator: thousandSeparator,
          fraction: intFraction,
          cents: decimal ? decimalFraction : null,
        }}
        size={size}
        centsType="superscript"
        currencyId={currencyId}
        styles={styles}
      />
    </div>
  );
};

Price.propTypes = {
  className: string,
  currencyId: string,
  decimal: string,
  id: string,
  size: string,
  styles: shape({}),
  text: string,
  thousandSeparator: string,
};

export default Price;
