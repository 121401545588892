import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--f1-car';

const ICON_ID = 'ic_f1_car';

const IconF1Car = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 26 12"
    iconId={ICON_ID}
    width="26"
    height="12"
  />
);

IconF1Car.propTypes = {
  className: string,
  style: string,
};

export default IconF1Car;
