import React from 'react';
import { string, oneOf } from 'prop-types';

import classnames from 'classnames';

import RenderComponent from './utils/renderComponent';
import LabelContent from './components/label-content';
import { getVariablesFromText } from './utils/utilities';
import { PACKAGE_NAMESPACE_LABEL } from './constants';

const LabelBuilder = (props) => {
  const { class_name, html_tag, text, type } = props;

  const Interpolationkeys = getVariablesFromText(text);

  if (Interpolationkeys) {
    return <LabelContent {...props} className={class_name} />;
  }

  const Component = RenderComponent(type);
  const Element = html_tag || 'span';

  return (
    <Element className={classnames(PACKAGE_NAMESPACE_LABEL, class_name)}>
      <Component {...props} />
    </Element>
  );
};

LabelBuilder.propTypes = {
  class_name: string,
  html_tag: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li']),
  text: string,
  type: string,
};

export default LabelBuilder;
