import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--highlight_brand';

const ICON_ID = 'is_highlight_brand';

const IconBigBrands = (props) => (
  <BuildIcon
    {...props}
    viewBox="0 0 20 20"
    className={classnames(namespace, props.className)}
    ariaHidden="true"
    iconId={ICON_ID}
    width="20"
    height="20"
  />
);

IconBigBrands.propTypes = {
  className: string,
  style: string,
};

export default IconBigBrands;
