import React from 'react';

import classnames from 'classnames';

import { useSearch } from '../../hooks/context';
import ComponentList from '../../utils/component-list';
import SeoContents from '../../components/seo-contents/seo-contents';

const availableComponents = {
  SEO_CONTENT_INTERVENTION: SeoContents,
};

const namespace = 'ui-search-footer-components';

const FooterComponents = () => {
  const { footer_components } = useSearch();
  const showFooterComponents = footer_components && footer_components.length > 0;

  if (!showFooterComponents) {
    return null;
  }

  return (
    <div className={classnames(namespace)}>
      <div className={classnames(`${namespace}__wrapper`)}>
        <div className={classnames(`${namespace}__container`)}>
          <ComponentList availableComponents={availableComponents} components={footer_components} />
        </div>
      </div>
    </div>
  );
};

export default FooterComponents;
