/**
 * Module dependencies
 */

import React from 'react';
import { any, bool, node, objectOf, shape, string } from 'prop-types';

import classnames from 'classnames';
import { Script } from 'nordic/script';
import { Style } from 'nordic/style';
import { MeliGA } from 'nordic/analytics/meli-ga';
import RedirectApp from 'redirect-app-web';
import { Hotjar } from 'nordic/hotjar';
import { Qualtrics } from 'qualtrics-wrapper';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import { BottomSheetProvider } from 'eshops-components-library';

import { LayoutListener } from '../../../../../components/context/layout';
import { useEshopsEnvironment } from '../../../../../components/context/eshop-environment';
import Metadata from '../../../../../components/metadata';
import GoogleTagManager from '../../../../../components/google-tag-manager';
import Schema from '../../../../../components/schema/schema';
import useHotjarActionsByCookie from '../../../../../hooks/use-hotjar-actions-by-cookie';
import { useLocationSearchInitialState, useLocationSearch } from '../../../../../components/context/location-search';
import ErrorBoundary from '../../../../../components-v2/error/error-boundary';
import { getEmbeddedData } from '../../../helpers/getEmbeddedData';
import WrapperEshops from '../../../../../components/eshops/wrapper-eshop';
import { SVG_SPRITES as svgSprites } from './constants';

const namespace = 'ui-search';

/**
 * Search Component
 */

const Search = ({
  children,
  currentUser = null,
  deviceType,
  initialState: serverState,
  siteId,
  cookies: initialCookies,
  platformId = '',
  groupSite = '',
  hotjar: initialHotjar,
  openInApp = null,
  nonce,
}) => {
  const cookies = initialCookies || {};
  const hotjar = initialHotjar || {};
  const { initialState } = useLocationSearch();

  const { shops, storefront: sF } = useLocationSearchInitialState();

  const isEshopsEnvironment = useEshopsEnvironment();

  const isShops = Boolean(shops);
  const embeddedData = getEmbeddedData(initialState, currentUser);

  useHotjarActionsByCookie(hotjar);

  const isPolycards = !!initialState.polycard_context;

  return (
    <div className={classnames(namespace, { 'shops__ui-main': isShops })}>
      {openInApp?.shouldRedirect && <RedirectApp {...openInApp} />}
      <Style href={`search.${deviceType}${groupSite}.css`} critical={deviceType === 'mobile'} />
      {isPolycards && <Style href="search.card.polycard.css" critical={deviceType === 'mobile'} />}
      {!isPolycards && deviceType === 'mobile' && initialState.layout_options.current === 'stack' && (
        <Style href="search.mobile.card.stack.css" critical />
      )}
      {serverState.find_user_location && <Script src="location.js" />}
      {initialState?.ads_metadata?.track_url && (
        <Script>
          {`
        var trackMclics = document.createElement('img');
        trackMclics.src = '${initialState.ads_metadata.track_url}';
      `}
        </Script>
      )}
      <Schema {...initialState.seo} />
      <LayoutListener />
      <BottomSheetProvider>
        <>
          <Hydrator ssrOnly>{svgSprites}</Hydrator>
          {isEshopsEnvironment && <WrapperEshops {...sF.appearance} siteId={siteId} />}
          {children}
          <Metadata {...initialState.seo} {...initialState.canonical_info} />
        </>
      </BottomSheetProvider>
      {/** @TODO all data shold be part of initialState */}
      {deviceType !== 'mobile' && <MeliGA {...initialState.analytics_track} />}
      {/** @TODO path should be part of initialState.melidata_track */}
      <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
        {`window.viewability.setNonceForCSP('${nonce}')`}
      </Script>
      <Hotjar {...hotjar} />
      {!initialState.shops && (
        <GoogleTagManager
          siteId={siteId}
          results={initialState.results}
          platform={platformId}
          cookies={cookies}
          queryInitialState={initialState.query}
          businessInitialState={initialState.analytics_track.business}
          pageVerticalInitialState={initialState.analytics_track.pageVertical}
        />
      )}
      <Script
        src="https://3e31081d8fc9.edge.sdk.awswaf.com/3e31081d8fc9/5b703b8b2026/challenge.js"
        crossOrigin={null}
        defer
      />
      <Qualtrics interceptId="ZN_efi6I3nkl8bGcTA" embeddedData={embeddedData} />
    </div>
  );
};

Search.propTypes = {
  billboardState: shape({}),
  children: node.isRequired,
  cookies: objectOf(any),
  country: shape({
    decimal_separator: string.isRequired,
    thousands_separator: string.isRequired,
  }).isRequired,
  currentUser: shape({
    loggedIn: bool.isRequired,
    loginUrl: string.isRequired,
    nickname: string.isRequired,
  }),
  deviceOs: string.isRequired,
  deviceType: string.isRequired,
  domain: string.isRequired,
  groupSite: string,
  hotjar: shape({}),
  imagePrefix: string,
  initialState: objectOf(any).isRequired,
  isHoverDisabled: bool,
  lowEnd: bool.isRequired,
  mapApiKey: string,
  nonce: string,
  openInApp: shape({
    shouldRedirect: bool,
  }),
  platformId: string,
  queryParams: objectOf(any),
  site: string,
  siteId: string.isRequired,
  visualId: string,
  webp: bool,
};

const SearchContainer = (props) => (
  <ErrorBoundary siteId={props.siteId}>
    <Search {...props} />
  </ErrorBoundary>
);

SearchContainer.propTypes = {
  siteId: string.isRequired,
};

/**
 * Inject i18n context as props into Search.
 */
export default SearchContainer;
