import React from 'react';

import FullIcon from '../sources/full';
import TourIcon from '../sources/virtual-tour';
import ChevronIcon from '../sources/chevron';
import ChevronIconRight from '../sources/icon-chevron-right';
import BlueCheckIcon from '../sources/blue-check';
import BigBrands from '../sources/big-brands';
import PremiumBrands from '../sources/premium-brands';
import InternationalIcon from '../sources/international-cbt';
import SamedayIcon from '../sources/sameday_truck';
import GoodFinancing from '../sources/good_financing';
import ShippingTruck from '../sources/shipping-truck';
import F1Car from '../sources/f1-car';
import F1CarTurbo from '../sources/f1-car-turbo';
import IconInternationalNoWorld from '../sources/international-cbt-no-world';

const staticsIconsList = {
  fulfillment: FullIcon,
  virtual_tour: TourIcon,
  virtual_tour_icon: TourIcon,
  chevron: ChevronIcon,
  chevron_right: ChevronIconRight,
  blue_check: BlueCheckIcon,
  is_highlight_brand: BigBrands,
  big_brands: BigBrands,
  premium_brands: PremiumBrands,
  is_highlight_brand_premium: PremiumBrands,
  cbt_international: InternationalIcon,
  nextday: SamedayIcon,
  good_financing: GoodFinancing,
  free: ShippingTruck,
  f1_carv2: F1Car,
  f1_car_turbov2: F1CarTurbo,
  international_cbt_no_world: IconInternationalNoWorld,
};

const staticsIconsFilters = (props) => {
  const IconComponent = staticsIconsList[[props.iconId]] || null;

  return IconComponent ? <IconComponent {...props} /> : null;
};

export default staticsIconsFilters;
