import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--badge';

const ICON_ID = 'ic_badge';

const IconBadge = (props) => (
  <BuildIcon {...props} className={classnames(namespace, props.className)} viewBox="0 0 18 19" iconId={ICON_ID} />
);

IconBadge.propTypes = {
  className: string,
};

export default IconBadge;
