import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--virtual-tour';
const ICON_ID = 'poly_tour';

const IconVirtualTour = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 23 12"
    ariaHidden
    iconId={ICON_ID}
    width="23"
    height="12"
  />
);

IconVirtualTour.propTypes = {
  className: string,
  style: string,
};

export default IconVirtualTour;
