import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--f1-car-turbo';

const ICON_ID = 'ic_f1_car_turbo';
const IconF1CarTurbo = (props) => (
  <BuildIcon
    {...props}
    viewBox="0 0 31 12"
    iconId={ICON_ID}
    className={classnames(namespace, props.className)}
    width="31"
    height="12"
  />
);

IconF1CarTurbo.propTypes = {
  className: string,
  style: string,
};

export default IconF1CarTurbo;
