import SOURCES from './sources';
import Label from '../components/label';

const RenderComponent = (type) => {
  const typeInUppercase = type?.toUpperCase();

  const { [typeInUppercase]: element } = SOURCES;

  return element ?? Label;
};

export default RenderComponent;
