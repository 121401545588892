import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import Highlight from '../../../card/highlight/highlight-label';
import IconLabel from '../../../../components-v2/icons/utils/icon-label'; // TODO: check if we can be removed this component
import StyledLabelWrapper from '../../../styled-label/styled-label';
import { FILTER_BASE_PROPS } from './constants';

// Default filter, only shows name
const HighlightedFilterContent = ({
  namespace = '',
  title = null,
  highlight = null,
  subtitle = null,
  label = null,
  htmlForIDValue = '',
  hasHtml,
}) => {
  const titleClassname = classnames(`${namespace}__title`, {
    [`${namespace}__meli-plus`]: title?.icons && title?.icons[0] === 'meli_plus_icon',
  });

  return (
    <>
      {title && <IconLabel className={titleClassname} htmlForIDValue={htmlForIDValue} hasHtml={hasHtml} {...title} />}
      {highlight && <Highlight htmlForIDValue={htmlForIDValue} {...highlight} />}
      {subtitle && <IconLabel className={`${namespace}__subtitle`} htmlForIDValue={htmlForIDValue} {...subtitle} />}
      {label && <StyledLabelWrapper className={`${namespace}__label`} htmlForIDValue={htmlForIDValue} {...label} />}
    </>
  );
};

HighlightedFilterContent.propTypes = {
  ...FILTER_BASE_PROPS,
  namespace: string,
};

export default HighlightedFilterContent;
