import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--international-no-world';
const ICON_ID = 'international-cbt-no-world';
const DEFAULT_COLOR = '#1F4E96';
const DEFAULT_DESCRIPTION = 'Compra Internacional';

const IconInternationalNoWorld = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 170 10"
    color={props.color || DEFAULT_COLOR}
    description={props.srLabel ?? DEFAULT_DESCRIPTION}
    iconId={ICON_ID}
    width="170"
    height="10"
  />
);

IconInternationalNoWorld.propTypes = {
  className: string,
  color: string,
  srLabel: string,
};

export default IconInternationalNoWorld;
