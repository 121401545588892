/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { string } from 'prop-types';

import { Style } from 'nordic/style';
import { ErrorScreen } from '@andes/technical-error';

import { getMessagesErrorBySiteId } from '../../lib/get-message-error';
import IconErrorScreen from '../icons/sources/error-screen';

const ErrorComponent = ({ siteId }) => {
  const { title, description } = getMessagesErrorBySiteId(siteId);

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      <Style href="search.error.css" />
      <div className="ui-search ui-search-error">
        <ErrorScreen
          asset={<IconErrorScreen />}
          className="ui-search-error__screen"
          description={description}
          title={title}
        />
      </div>
    </>
  );
};

ErrorComponent.propTypes = {
  siteId: string.isRequired,
};

export default ErrorComponent;
