import React from 'react';
import { shape, string, arrayOf, bool, number, oneOfType } from 'prop-types';

import { Pagination } from '@andes/pagination';

import WrapperPagination from './components/wrapperPagination';
import { formatUrl } from './utils/helpers';

const namespace = 'ui-search-andes-pagination';

const PaginationDesktop = ({ paginationNodes }) => {
  const actualNode = paginationNodes.find((node) => node.is_actual_page);
  const pageSelected = Number(actualNode.value);
  const lastNode = paginationNodes[paginationNodes.length - 1];
  const lastPage = Number(lastNode.value);

  return (
    <Pagination
      className={namespace}
      pageSelected={pageSelected}
      pageQuantity={lastPage}
      formatUrl={formatUrl(paginationNodes, 0, lastPage)}
    />
  );
};

PaginationDesktop.propTypes = {
  paginationNodes: arrayOf(
    shape({
      value: oneOfType([number, string]),
      url: string,
      is_actual_page: bool,
    }),
  ).isRequired,
};

const DesktopPagination = () => WrapperPagination(PaginationDesktop);

export default DesktopPagination;
