import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--free-shipping';
const ICON_ID = 'good_financing';

const IconGoodFinancing = (props) => (
  <BuildIcon
    {...props}
    iconId={ICON_ID}
    viewBox="0 0 16 16"
    ariaHidden
    className={classnames(namespace, props.className)}
    width="16"
    height="16"
  />
);

IconGoodFinancing.propTypes = {
  className: string,
  style: string,
};

export default IconGoodFinancing;
