import React, { useMemo } from 'react';
import { arrayOf, shape, string, oneOf } from 'prop-types';

import { splitVariablesAndText } from '../utils/utilities';
import renderComponentsFromBuilder from '../utils/renderComponentsFromBuilder';
import { PACKAGE_NAMESPACE_LABEL } from '../constants';

const LabelContent = (props) => {
  const { html_tag, sourceIcons, text, values } = props;
  const Element = html_tag || 'span';

  const result = splitVariablesAndText(text);

  const componentsToRender = useMemo(
    () => renderComponentsFromBuilder({ result, values, props, sourceIcons }),
    [result, values, props, sourceIcons],
  );

  return <Element className={PACKAGE_NAMESPACE_LABEL}>{componentsToRender}</Element>;
};

LabelContent.propTypes = {
  html_tag: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li']),
  sourceIcons: arrayOf(
    shape({
      className: string,
      color: string,
      description: string,
      icon: string.isRequired,
      key: string,
      size: string,
    }),
  ),
  text: string,
  values: arrayOf(
    shape({
      className: string,
      color: string,
      description: string,
      icon_id: string,
      key: string,
      text: string,
      type: string,
      styles: shape({}),
    }),
  ),
};

export default LabelContent;
